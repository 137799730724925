.admin-landing-page {
  position: relative;
  height: 100vh;
  background: linear-gradient(165deg, #270446 0%, #02010b 100%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spot {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  opacity: 0.6;
  animation: moveSpots 10s infinite ease-in-out;
}

.spot:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 20%;
  left: 30%;
  animation-delay: 0s;
}

.spot:nth-child(2) {
  width: 400px;
  height: 400px;
  top: 40%;
  left: 60%;
  animation-delay: 2s;
}

.spot:nth-child(3) {
  width: 250px;
  height: 250px;
  top: 70%;
  left: 20%;
  animation-delay: 4s;
}

.spot:nth-child(4) {
  width: 350px;
  height: 350px;
  top: 80%;
  left: 50%;
  animation-delay: 6s;
}

@keyframes moveSpots {
  0%,
  100% {
    transform: translateY(0px);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-50px);
    opacity: 0.9;
  }
}
.minus-margin-top {
  margin-top: -50px;
}
