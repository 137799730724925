/* AdminLandingPage.css */
.admin-background {
  position: relative;
  background: linear-gradient(165deg, #270446 0%, #02010b 100%);
  min-height: 100vh;
  padding-bottom: 50px; /* Ensure padding at the bottom to avoid cut-off content */
  overflow: hidden;
}

/* Ensure the body and html do not have overflow set */
html,
body {
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
}

@media (max-width: 768px) {
  .admin-background {
    padding: 10px;
  }
}

.spot {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  opacity: 0.6;
  animation: moveSpots 10s infinite ease-in-out;
}

.spot:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 20%;
  left: 30%;
  animation-delay: 0s;
}

.spot:nth-child(2) {
  width: 400px;
  height: 400px;
  top: 40%;
  left: 60%;
  animation-delay: 2s;
}

.spot:nth-child(3) {
  width: 250px;
  height: 250px;
  top: 70%;
  left: 20%;
  animation-delay: 4s;
}

.spot:nth-child(4) {
  width: 350px;
  height: 350px;
  top: 80%;
  left: 50%;
  animation-delay: 6s;
}

@keyframes moveSpots {
  0%,
  100% {
    transform: translateY(0px);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-50px);
    opacity: 0.9;
  }
}
.chart-container {
  width: 100%;
  max-width: 100%;
  height: auto; /* Ensure the chart container is responsive */
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px; /* Set a smaller height for mobile */
  }

  /* Adjust the spot sizes for mobile */
  .spot {
    width: 150px;
    height: 150px;
  }

  .spot:nth-child(1) {
    top: 10%;
    left: 10%;
  }

  .spot:nth-child(2) {
    top: 30%;
    left: 50%;
  }

  .spot:nth-child(3) {
    top: 50%;
    left: 20%;
  }

  .spot:nth-child(4) {
    top: 70%;
    left: 40%;
  }
}

.transparent-table {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent background */
  border: none;
  color: white !important; /* Override text color to make it readable */
}

.transparent-table th,
.transparent-table td {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent background for table cells */
  color: white !important; /* Ensure text remains readable */
  border-color: rgba(
    255,
    255,
    255,
    0.2
  ) !important; /* Light white border to maintain table grid */
}

.transparent-table thead th {
  background: linear-gradient(to right, #971ca2, #a41160) !important;
  color: white !important;
  padding: 10px;
}
.transparent-table tbody tr {
  padding: 5px;
}
.transparent-table tbody tr:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ) !important; /* Optional: Add hover effect with slight transparency */
}
