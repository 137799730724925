.navbar {
  position: relative;
  z-index: 1000; /* Ensure the navbar sits on top of other elements */
}

.navbar-toggler.collapsed {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.nav-link {
  padding: auto !important;
  margin-bottom: 0px !important;
}
.navbar-toggler-icon {
  transition: all 0.3s ease;
  color: rgb(255, 255, 255) !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon:hover {
  transform: scale(1.2);
}

.navbar-collapse {
  transition: all 0.5s ease;
}

.navbar-collapse.show {
  display: block;
}

/* Button adjustments */
.btn-danger {
  padding: 8px 12px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-danger:hover {
  background-color: #c82333;
}

/* Mobile-specific animation for navbar toggling */
@media (max-width: 991.98px) {
  .navbar-collapse {
    display: none;
    transition: all 0.5s ease;
  }

  .navbar-collapse.show {
    display: block;
    animation: fadeInDown 0.3s ease forwards;
  }

  .navbar-collapse:not(.show) {
    animation: fadeOutUp 0.3s ease forwards;
  }
}
.nav-item {
  margin-bottom: 0px !important;
}
