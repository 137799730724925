/* Loading.css */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000b5 !important; /* Semi-transparent #02010b */
  z-index: 9999;
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #ba2556; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

/* Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
