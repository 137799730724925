.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0d154d;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  font-family: "Poppins", sans-serif;
}

.feedback-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
}

.feedback-form h3 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.btn-submit {
  background: linear-gradient(to right, #c65bcf, #ff3ea5) !important;
  color: #fff !important;
  padding: 7px 40px !important;
  border: none !important;
  border-radius: 50px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn-submit:hover {
  background: linear-gradient(90deg, #ff3ea5 0%, #c65bcf 100%) !important;
  color: #fff !important;
  border-radius: 50px;
}

.btn-submit-danger {
  background: linear-gradient(90deg, #6f1a16 0%, #fc1a1a 100%);
  color: #fff !important;
  padding: 7px 40px !important;
  border: none !important;
  border-radius: 50px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn-submit-danger:hover {
  background: linear-gradient(90deg, #fc1a1a 0%, #6f1a16 100%);
  color: #fff !important;
  border-radius: 50px;
}
.navbar {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent #02010b */
}
.nav-btn-submit {
  background: linear-gradient(to right, #c65bcf, #ff3ea5) !important;
  color: #fff !important;
  padding: 7px 30px !important;
  border: none;
  border-radius: 50px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px !important;
  /* font-weight: bold !important; */
  margin: 0 3px !important;
}

.nav-btn-submit:hover {
  background: linear-gradient(90deg, #ff3ea5 0%, #c65bcf 100%) !important;
  color: #fff !important;
  border-radius: 50px;
}

/* Custom card background */
.transparent-card {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent #02010b */
  border: none;
  color: white !important; /* Make text white for readability */
}
.gradient-card {
  background: linear-gradient(
    90deg,
    rgba(255, 62, 165, 0.8) 0%,
    rgba(198, 91, 207, 0.8) 100%
  ) !important;
  border: none;
  color: white; /* Make text white for readability */
}

.transparent-card label {
  color: white; /*Make form text white */
}

.overlay-content {
  position: relative;
  z-index: 2;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-fit: cover; /* Ensures the video covers the entire screen */
}

/* Add background with gradients and spots */
.dashboard-background {
  background: linear-gradient(165deg, #270446 0%, #02010b 100%);
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

/* Add spot-like shapes */
.dashboard-background::before,
.dashboard-background::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
}

.dashboard-background::before {
  width: 400px;
  height: 400px;
  background-color: #ffffff;
  top: -50px;
  right: -50px;
}

.dashboard-background::after {
  width: 300px;
  height: 300px;
  background-color: #f8f9fa;
  bottom: -100px;
  left: -100px;
}

body {
  background-color: #02010b;
  background-image: linear-gradient(165deg, #270446 0%, #02010b 100%);
  color: white;
}
.navbar-toggler-icon {
  transition: all 0.3s ease;
  color: rgb(255, 255, 255) !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 768px) {
  .navbar-nav .nav-item {
    text-align: left;
    padding: 20px 10px 0px 10px;
  }
}
.form-control {
  background-color: #ebeaea !important; /* Slightly darker light background on focus */
}
/* Scrollbar styling */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #270446;
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #8783b4;
}

::-webkit-scrollbar-track {
  background: #270446;
  border-radius: 6px; /* Roundness of the scrollbar track */
}

* {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #8783b4 #270446; /* Thumb color and track color */
}
.display-4 {
  font-size: calc(1.2rem + 2vw) !important; /* Decrease the base size */
  font-weight: 300;
  line-height: 1.2;
}
lead {
  font-size: 1rem !important;
  font-weight: 300;
}
td {
  font-size: 13px !important;
}
.font-small {
  font-size: 13px !important;
}
