.button_border {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  transition: background 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  border-radius: 50px;
  padding: 6px 40px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensures the button text is on top */
}

.button_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ff3ea5 0%, #c65bcf 100%);
  z-index: 0; /* Lower z-index to keep the gradient below the text */
  transition: transform 0.4s ease;
  transform: scaleX(0); /* Starts with no background gradient */
  transform-origin: right;
}

.button_border:hover::before {
  transform: scaleX(1); /* Expands the gradient background */
  transform-origin: left;
}

.button_border:hover {
  color: #ffffff;
  border-color: transparent;
  z-index: 1; /* Ensure the text stays above the gradient background */
}

.button_border span {
  position: relative;
  z-index: 2; /* Ensure the text is always above the gradient background */
}

.landing-header {
  height: 550px; /* Set the header height to 400px */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* background-image: url("../../public/images/5166950.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
}
.icon_color {
  color: #ffffff;
}
.background_blue {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent #02010b */
}
.image {
  height: 250px;
  /* width: 350px; */
}
/* LandingPage.css */

/* Ensure the video container takes up the full screen */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Ensures the video stays in the background */
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay content to ensure it sits on top of the video */
.overlay-content {
  position: relative;
  z-index: 1;
  color: white;
}

/* Styling the about section */
.about-section {
  padding: 50px 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Additional project card styling */
.image {
  height: 200px;
  object-fit: cover;
}

.heading {
  color: #ffffff;
}

/* Gradient button styling */

/* Footer styles */
.background_blue {
  background-color: #161d6f;
}

/* Adjust the video for responsiveness */
@media (max-width: 768px) {
  .video-container {
    display: block; /* Ensure the video is visible */
    width: 100%;
    height: 100vh; /* Set the height to the viewport height for mobile */
  }

  .background-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.paragraph {
  color: #ffffff;
  font-size: 18px;
  line-height: 1.8rem;
}

.faq-section {
  background-color: rgba(
    2,
    1,
    11,
    0.45
  ) !important; /* Semi-transparent background */
  border: none;
  color: white !important;
  padding: 30px;
  border-radius: 8px;
}

.accordion-button {
  background-color: transparent !important;
  font-size: 18px !important;
  color: white;
  font-weight: bold;
  border: none;
  margin: 10px 0px !important;
}

.accordion-item {
  background-color: transparent !important;
  border: none;
  color: #ffffff;
}

.accordion-body {
  background-color: transparent !important;
  color: white;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2) !important; */
}

.accordion-header {
  font-size: 20px !important;
  color: white !important;
  font-weight: bold;
}

.accordion-button::after {
  filter: invert(1) !important; /* Make the icon (caret) white */
  color: white !important;
}
.accordion-button:hover {
  color: white !important; /* Ensure the text stays white on hover */
}
