.chart-container {
  position: relative;
  height: 300px;
  width: 100%;
}

@media (max-width: 768px) {
  .chart-container {
    height: 250px;
  }
}
.icon-card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.icon-card:hover {
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .d-flex {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .chart-container {
    height: 250px; /* Adjust for smaller screens */
  }
}
.card-title {
  font-size: 1rem !important;
}
